.Minitools-heading{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: large;
    color: white;
}

.Minitools-body {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
    flex-wrap: wrap;
  }

.Minitools-title {
    width: 100%;
    padding: 0.5rem 0rem;
    background-color: #282c34;
    color: white;
}

label{
    color: white;
    padding-right: 20px;
}

th{
    padding-left: 20px;
    padding-right: 20px;
}

.Minitools-malelabel{
    padding-left: 10px;
    padding-right: 10px;
}

.Minitools-table{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Minitools-dateblock{
    display: flex;
    align-items: stretch;
    padding-bottom: 10px;
}

.Minitools-datepicker{
    padding-right: 10px;
}

.Minitools-eventname{
    text-align: left;
}

.Minitools-genlist{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}

.Minitools-gendiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Minitools-gendiv input{
    margin-bottom: 5px;
}

.canbreed{
    background-color: lightgreen;
}

.Minitools-numberInput{
    display: flex;
    flex-direction: row;
}

.Minitools-numberInputInput{
    max-width: 60px;
}

.Minitools-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.Minitools-maletoggle{
    padding-bottom: 60px;
    justify-content: space-around;
}

.Minitools-tabs {
    width: 80%;
    height: auto;
    min-height: 400px;
    background: #053742;
    margin: 1rem auto 1.5rem;
    padding: 2rem 1rem;
    color: #E8F0F2;
    border-radius: 2rem;
    @media (max-width: 769px) {
     padding: 2rem 0;
    }
}

.Minitools-numbertext{
    max-height: 17px;
    max-width: 150px;
    resize: none;
}

.Minitools-textnumbertext{
    max-height: 100px;
    max-width: 300px;
    min-width: 300px;
    resize: none;
}

.Minitools-ratiotext{
    margin-top: 5px;
    max-height: 17px;
    max-width: 100px;
    resize: none;
}

.Minitools-ratiolabel{
    padding-right: 5px;
} 

.Minitools-tdright{
    text-align: right;
    vertical-align: middle;
}

.Minitools-tdleft{
    text-align: left;
    vertical-align: middle;
}

.Minitools-ratioflex{
    display: flex;
    flex-direction: row;
    align-items: center;
}


ul.Minitools-tabnav {
    width: 60%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid cadetblue;
    border-radius: 2rem;
    padding-left: 0px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  ul.Minitools-tabnav li {
    width: 50%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
  }
  ul.Minitools-tabnav li:nth-child(2) {
    border-radius: 0;
  }
  ul.Minitools-tabnav li:nth-child(3) {
    border-radius: 0;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }
  ul.Minitools-tabnav li:hover {
    background: rgba(50, 224, 196, 0.15);
  }
  ul.Minitools-tabnav li.active {
    background: cadetblue;
  }