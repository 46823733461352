
.navigation {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #282c34;
    color: white;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  }
  
  .brand-name {
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    margin-left: 1rem;
  }
  .navigation-menu {
    margin-left: auto;
  }
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navigation-menu li {
    /* removes default disc bullet for li tags and applies margin to left & right side */
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    /* increases the surface area of the anchor tag to span more than just the anchor text */
    text-decoration: none;
    color: lightcyan;
    display: block;
    width: 100%;
  }

  .navigation-menu li a:visited {
    color: cadetblue;
  }

  
  .hamburger {
    /* removes default border on button element */
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: transparent;
    color: cadetblue;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: darkslategrey;
  }
  
  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
    .navigation-menu.expanded ul {
        position: absolute;
        top: 60px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 77px);
        background-color: #282c34;
        border-top: 1px solid black;
        display: block;
        z-index: 100;
    }
    .navigation-menu ul {
        position: absolute;
        top: 60px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 77px);
        background-color: #282c34;
        border-top: 1px solid black;
        display: none;
      }
      .navigation-menu li {
        text-align: center;
        margin: 0;
      }
      .navigation-menu li a {
        color: lightgrey;
        width: 100%;
        padding: 1.5rem 0;
      }
      .navigation-menu li:hover {
        background-color: darkslategrey;
      }
  }
